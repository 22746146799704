import { GetBodyInnerHTML } from "../../modules/services/getElements.service";
import { gtmFullVideoPlayed } from "../../tools/datalayers";

const LeaderModal = (props: any) => {
    const { onClose, leader, video, imageSelected } = props;
    const { description, image, link, title, email, phone, descriptionLeader } = leader ?? {};
    const { src, title: videoName } = video ?? {};

    if (video) {
        return (
            <div className="modal-container">
                <div className="modal-body-video">
                    <img
                        className='close-modal-button white'
                        src={`${process.env.PUBLIC_URL}/customs/main-mx/icon-close.svg`}
                        alt="Cerrar"
                        onClick={onClose}
                    />
                    <video
                        autoPlay
                        controls
                        src={src?.src}
                        width='100%'
                        onEnded={() => gtmFullVideoPlayed(videoName)}
                    />
                </div>
            </div>
        )
    } else if (imageSelected) {
        return (
            <div className="modal-container">
                <div className="modal-body-video">
                    <img
                        className='close-modal-button'
                        src={`${process.env.PUBLIC_URL}/customs/main-mx/icon-close.svg`}
                        alt="Cerrar"
                        onClick={onClose}
                    />
                    <div className="multimedia-item">
                        <img
                            src={imageSelected.src}
                            alt="Imagen"
                            className="rounded img-modal"
                        />
                    </div>
                </div>
            </div>
        )
    } else if (leader) {
        
        return (
            <div className="modal-container modal-leader">
                <div className="modal-body">
                    <img
                        className='close-modal-button'
                        src={`${process.env.PUBLIC_URL}/customs/main-mx/icon-close.svg`}
                        alt="Cerrar"
                        onClick={onClose}
                    />
                    <div className="modal-photo">
                        <img
                            className='leader-photo'
                            alt={title}
                            src={image}
                        />
                    </div>
                    <div className="leader-content">
                        <div className="leader-info name">
                            {title}
                        </div>
                        <div className="leader-info">
                            <GetBodyInnerHTML content={description} />
                        </div>
                        {email && <div className="leader-info">
                            <img
                                alt='Email'
                                src={`${process.env.PUBLIC_URL}/customs/main-mx/icon-email.svg`}
                                className = "img-item-email"
                            />
                            {`  ${email}`}
                        </div>}
                        {phone && <div className="leader-info">
                            <img
                                alt='Teléfono'
                                src={`${process.env.PUBLIC_URL}/customs/main-mx/icon-phone.svg`}
                                className = "img-item-email"
                            />
                            {`  ${phone}`}
                        </div>}
                        {
                            descriptionLeader?.value && (
                                <div className="leader-info">
                                    <GetBodyInnerHTML content={descriptionLeader?.value} />
                                </div>
                            )

                        }
                        {link?.value &&
                            <button className="btn btn-secondary">{link.value}</button>
                        }
                       
                    </div>
                </div>
            </div>
        )
    }
    return <></>
}

export default LeaderModal;